import React from "react";
import FunctionPageLayout from "../components/functionPageLayout";
import { graphql } from "gatsby";
import SEO from "../components/seo";
import Lightbox from "../components/lightBox";
import BenefitBlockWrapper from "../components/BenefitBlockWrapper";
import BenefitBlock from "../components/benefitBlock";
import {
  FaClock,
  FaList,
  FaUsers,
  FaPuzzlePiece,
  FaSchool,
  FaPlus,
  FaSlidersH,
  FaLaptop,
  FaCreditCard,
  FaGraduationCap,
} from "react-icons/fa";
import { BsFillAlarmFill,
BsFillBriefcaseFill,
BsFillGiftFill,
BsEmojiLaughingFill,
BsToggles,
BsGridFill,
 } from "react-icons/bs";
import Container from "../components/container";
import * as styles from "./index.module.css";
import Tile from "../components/Tile";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import heroImage2 from "../images/system-crm-dla-firmy-handlowej-i-uslugowej.jpg";
import Columns from "../components/columns";
import ResponsiveEmbed from "react-responsive-embed";

export const query = graphql`
  {
    rekrutacja: file(relativePath: { eq: "dolaczdonas.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;

const BusinessDevelopment = ({ data }) => {
  return (
    <FunctionPageLayout title="Business Development Manager" headline="Dołącz do zespołu">
      <SEO
        title="Business Development Manager - oferta pracy"
        description="Producent oprogramowania dla firm ✅ Oferta pracy • Business Development Manager • Skontaktuj się z nami"
      />
      <Container>
        <h2
        style={{
          paddingTop: 20,
          paddingBottom: 20,
        }}
      > Kim jesteśmy? </h2>
      <p>
       Jesteśmy producentem i dostawcą oprogramowania działającym na polskim rynku już
        od 2002 roku. Pracujemy zarówno dla dużych, rozpoznawalnych firm, jak&nbsp;i&nbsp;dla 
        mniejszych, dynamicznie rozwijających się przedsiębiorstw. Nasz zespół składa się z ponad 50
        specjalistów, których łączy wspólna pasja – technologie informacyjne.
        </p>
        <p>Fundamentem naszej codziennej działalności jest <strong>dostarczanie rozwiązań informatycznych, które
        efektywnie wspierają rozwój biznesu</strong>. Naszą ambicją i&nbsp;długofalową wizją – zostanie najlepszym 
        dostawcą systemów IT wykorzystywanych w&nbsp;organizacjach na całym świecie.
        </p>
        <h2  style={{
          paddingTop: 20,
          paddingBottom: 15,
        }}>Wierzymy, że:</h2>
        <p><ul>
        <li>Tworząc rozwiązania wysokiej jakości, przyczyniamy się do rozwoju naszych klientów,</li>
        <li>Miarą naszego sukcesu jest sukces klienta i&nbsp;z&nbsp;zaangażowaniem walczymy o jego osiągnięcie,</li>
        <li>Nasi pracownicy i klienci są partnerami, którzy wspólnie budują długoterminową przyszłość,</li>
        <li>Dzięki współpracy osiągniemy więcej niż każdy z&nbsp;nas działając osobno,</li>
        <li>Inicjatywa i zaangażowanie są podstawą rozwoju i&nbsp;zasługują na uznanie,</li>
        <li>Ciągłe doskonalenie metod działania oraz&nbsp;produktu jest dla nas czymś oczywistym.</li></ul></p>
      </Container>
      <h2
        style={{
          paddingBottom: 30,
          paddingTop: 20,
        }}
      >
        Tworzymy zgrany zespół
      </h2>

      <Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
            <p>
              To, co motywuje nas do codziennego działania to współpraca. <br />
              Każdego dnia wspólnie tworzymy projekty, rozwiązujemy problemy
              naszych klientów, świętujemy nasze sukcesy, świetnie się przy tym
              wszystkim bawiąc.
              <br />
              <strong>
                {" "}
                Przecież w dobrej atmosferze obowiązki stają się przyjemnością,
                prawda?
              </strong>
            </p>
          </div>
          <div className="text-block">
            <ResponsiveEmbed
              src="https://www.youtube.com/embed/lLKbdB1I0xo"
              allowFullScreen
              ratio="16:9"
            />
          </div>
        </div>
      </Container>
      <h2
        style={{
          paddingTop: 30,
          paddingBottom: 30,
          textAlign: "center",
        }}
      >
        {" "}
        Dlaczego warto z nami pracować?{" "}
      </h2>

      <div>
        <BenefitBlockWrapper>
          <BenefitBlock
            icon={<BsFillBriefcaseFill size="60px" />}
            title="Już od 2002 roku budujemy doświadczenie w&nbsp;biznesie"
          />
          <BenefitBlock
            icon={<BsEmojiLaughingFill size="60px" />}
            title="Ponad 50 zapalonych fanów technologii na&nbsp;pokładzie"
          />
          <BenefitBlock
            icon={<BsGridFill size="60px" />}
            title="Realizujemy projekty z&nbsp;różnych dziedzin"
          />
        </BenefitBlockWrapper>
        <BenefitBlockWrapper>
          <BenefitBlock
            icon={<FaGraduationCap size="60px" />}
            title="Dzielimy się wiedzą i&nbsp;doświadczeniem"
          />
          <BenefitBlock
            icon={<BsToggles size="60px" />}
            title="Jesteśmy elastyczni i&nbsp;do każdego podchodzimy indywidualnie"
          />
          <BenefitBlock
            icon={<BsFillGiftFill size="60px" />}
            title="Oferujemy dodatkowe benefity"
          />
        </BenefitBlockWrapper>
      </div>

      <br />
      <h2> Oferta pracy </h2>
      <center>
        <h3>Business Development Manager</h3>
        <p></p>
      </center>
      <br />
      <center>
      <p>
        <strong>
          Twoim celem będzie zapewnienie wzrostu Questy przez pozyskiwanie, utrzymanie i rozwój współpracy z klientami.
        </strong>
      </p>
      </center>
      <br />
      <p>
        <strong>Czym będziesz się zajmować?</strong>
      </p>
      <p>
        <ul>
          <li>
            Zapewnieniem dopływu nowych szans sprzedażowych poprzez polecenia i networking,
          </li>
          <li>
            Zapewnieniem wzrostu firmy poprzez pozyskiwanie nowych klientów i kontraktów z szans sprzedażowych,
          </li>
          <li>
          Zapewnieniem zrozumienia potrzeb klienta oraz poszukiwanie możliwości ich zaspokojenia,
          </li>
          <li>
           Zapewnieniem utrzymania i rozwoju efektywnej współpracy z pozyskanymi klientami,
          </li>
          <li>
          Zapewnieniem sukcesu biznesowego dostarczanych rozwiązań oraz utrzymaniem pozytywnych relacji z klientem.
          </li>
        </ul>
      </p>
      <br />
      <p>
        <strong>Jakiej osoby szukamy?</strong>
      </p>
      <p>
        <ul>
          <li>
          Inteligentnej z analitycznym umysłem,
          </li>
          <li>
          Energicznej z pozytywnym nastawieniem do wyzwań,
          </li>
          <li>
          Z łatwością dostrzegającej potrzeby i możliwości,
          </li>
          <li>
          Niezrównanej w nawiązywaniu kontaktów i relacji,
          </li>
          <li>
         Zorientowanej na cele i sukcesy,
          </li>
          <li>
           Myślącej biznesowo i zgodnie z zasadami etyki,
          </li>
          <li>
           Dbającej o ciągły rozwój osobisty,
          </li>
          <li>
          Otwartej na opinie innych w celu poszerzania własnych horyzontów.
          </li>
        </ul>
      </p>
      <br />
      <p>
        <strong>
          Dobrze, jeśli:
        </strong>
      </p>
      <p>
        <ul>
          <li>
            Jesteś efektywny/a w komunikacji,
          </li>
          <li>
            Z łatwością odkrywasz i analizujesz potrzeby klienta biznesowego,
          </li>
          <li>
            Potrafisz zebrać wymagania oraz zaprezentować rozwiązania,
          </li>
          <li>
            Posiadasz prawo jazdy kat.B,
          </li>
          <li>
           Znasz zagadnienia związane ze wsparciem sprzedaży, logistyki lub zarządzania usługami przedsiębiorstw realizowanymi poprzez rozwiązania informatyczne- ERP, CRM, E-commerce (nie są wymagane),
          </li>
          <li>
         Posiadasz doświadczenie w pracy z klientem biznesowym zatrudniającym powyżej 50 pracowników.
          </li>
          </ul>
          </p>
      <br />
      <p>
        {" "}
        <strong>Co zyskujesz dołączając do naszego zespołu?</strong>{" "}
      </p>
      <p>
        <ul>
          <li>
            Szansę na poznanie świata IT od kuchni.
          </li>
          <li>
            Możliwość zdobycia wiedzy oraz praktycznego doświadczenia.
          </li>
          <li>
            Pracę w systemie jednozmianowym.
          </li>
          <li>
            Szansę na długoterminową współpracę po 3-miesięcznym okresie próbnym.
          </li>
          <li>Elastyczny grafik, jeśli jesteś studentką/studentem.</li>
          <li>Ciekawą pracę w dynamicznej firmie nastawionej na przyszłość i rozwój.</li>
          <li><strong>Szansę na realizowanie własnych pomysłów</strong>, bo w naszej firmie stawiamy na innowacyjność.</li>
          <li>
           Przyjazną atmosferę i spotkania integracyjne.
          </li>
          <li>
Pracę w biurze w centrum Poznania (3 min pieszo od Dworca Zachodniego).
          </li>
          <li>
          Dofinansowanie do karty Multisport.
          </li>
          <li>
          Możliwość pracy w trybie hybrydowym po 3-miesięcznym okresie próbnym.
          </li>
          <li>
          Zatrudnienie w formie zlecenia lub umowy o pracę.
          </li>
        </ul>
      </p>
            <br />

       <p><strong>Jeśli spełniasz większość z podanych przez nas wymagań, śmiało APLIKUJ!</strong></p>

      <Container>
        <h2
          style={{
            textAlign: "center",
            paddingTop: 20,
          }}
        >
          Przekonaliśmy Cię?
        </h2>
        <p style={{
          paddingBottom: 20,
          textAlign: "center", }}>
        Zobacz, jak do nas dołączyć.{" "}
      </p>
        <GatsbyImage
          image={data.rekrutacja.childImageSharp.gatsbyImageData}
          style={{
            maxWidth: 1200,
            margin: "0 auto",
            marginTop: 30,
          }}
          alt="rekrutacja"
        />

        <br />
        <br />
      </Container>
      <h2> Czekamy na Ciebie! </h2>
      <br />
      <br />
      <center>
        <p>
          Wyślij CV z dopiskiem <strong>„Business Development Manager”</strong> oraz zgodą
          na przetwarzanie danych osobowych w procesie rekrutacji na adres
          <strong> kariera@questy.pl </strong>{" "}
        </p>

        <p>
          {" "}
          Informujemy, że skontaktujemy się osobiście tylko z wybranymi
          kandydatami.{" "}
        </p>
      </center>
      <br />
      <br />
      <br />

      <p>Prosimy o zawarcie w CV klauzuli: </p>

      <p>
        „Wyrażam zgodę na przetwarzanie danych osobowych zawartych w mojej
        ofercie pracy dla potrzeb niezbędnych do realizacji procesu rekrutacji
        prowadzonego przez QUESTY z siedzibą w Poznaniu zgodnie z ustawą z dnia
        29 sierpnia 1997 r. o ochronie danych osobowych (t.j. Dz. U. z 2016 r.,
        poz. 922)”. Jednocześnie wyrażam zgodę na przetwarzanie przez
        ogłoszeniodawcę moich danych osobowych na potrzeby przyszłych
        rekrutacji.”
      </p>
      <p>
        Informujemy, że Administratorem danych jest QUESTY z siedzibą w Poznaniu
        przy ul. Głogowskiej 31/33. Dane zbierane są dla potrzeb rekrutacji. Ma
        Pani/Pan prawo dostępu do treści swoich danych oraz ich poprawiania.
        Podanie danych w zakresie określonym przepisami ustawy z dnia 26 czerwca
        1974 r. Kodeks pracy oraz aktów wykonawczych jest obowiązkowe. Podanie
        dodatkowych danych osobowych jest dobrowolne.
      </p>
    </FunctionPageLayout>
  );
};

export default BusinessDevelopment;
